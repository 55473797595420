import { Component, Input, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import * as _ from 'lodash';

@Component({
  selector: 'app-ticket-messages',
  templateUrl: './ticket-messages.component.html',
  styleUrl: './ticket-messages.component.scss',
})
export class TicketMessagesComponent implements OnInit {
  @Input() supportTicket: any;

  public loading: boolean = true;

  constructor() {}

  ngOnInit() {
    if (this.supportTicket) {
      this.supportTicket.timeAgo = DateTime.fromISO(this.supportTicket.created_at).toRelative();

      this.supportTicket.conversations.forEach((conversation) => {
        conversation.timeAgo = DateTime.fromISO(conversation.created_at).toRelative();
        const conversationFrom =
          !conversation.from_email || _.isNil(conversation.from_email) ? '' : conversation.from_email;
        conversation.authorClassName = conversationFrom.includes('@machool.com') ? 'author-machool' : 'author-user';
        conversation.cardClass = conversationFrom.includes('@machool.com') ? 'bg--accent-gray' : 'bg--light-green';
        if (conversation.private) {
          conversation.authorClassName += ' private';
          conversation.cardClass = 'bg--danger-light';
        }
      });
      this.loading = false;
    }
  }
}
