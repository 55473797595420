<ng-container *ngIf='!loading, else isLoading'>
  @if (noCard) {
    <ng-container *ngTemplateOutlet='shipmentList' ></ng-container>
  } @else {
  <mat-card class='mb--md'>
    <mat-card-content class='pb--none'>
      <ng-container *ngTemplateOutlet='shipmentList' ></ng-container>
    </mat-card-content>
  </mat-card>
  }
</ng-container>
<ng-template #shipmentList>
  <ng-container *ngIf='paginatedShipments && paginatedShipments.length > 0, else noShipments'>
    <div class='responsive-table'>
      <table
        mat-table
        matSort
        (matSortChange)="sortData($event)"
        [dataSource]="paginatedShipments"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef style='width: 50px'></th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            <button
              mat-icon-button
              color='primary'
              class='mat-small-icon mx--xs'
              (click)='toggleShipmentDetails(element)'
            ><mat-icon [ngClass]="element === expandedElement ? 'icon-minus' : 'icon-plus'"></mat-icon></button>
          </td>
        </ng-container>
        <ng-container matColumnDef="tracking">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style='width: 15%'>Tracking Number</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.tracking }}
          </td>
        </ng-container>
        <ng-container matColumnDef="provider_type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style='width: 10%'>Courier</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.providerName }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="trackingStatusVerbose" style='width: 10%'>Status</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            <div class='pill-box pill-status' [ngClass]="element.trackingStatus">{{ element.trackingStatusVerbose }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="invoice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Machool Invoice</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.invoices }}
          </td>
        </ng-container>
        <ng-container matColumnDef="hasCourierInvoice">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Courier Invoice</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.hasCourierInvoice }}
          </td>
        </ng-container>
        <ng-container matColumnDef="claimCustomerAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Cost</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.claimCustomerAmount | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="claimMachoolAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Machool Cost</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.claimMachoolAmount | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="claimCreditAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Credit</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.claimCreditAmount | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="claimPaymentAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Payable</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.claimPaymentAmount | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="cost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.machoolTotalAmount | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="revenue">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Revenue</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.customerTotalAmount | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="marginAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Margin $</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.marginAmount | currency }}
          </td>
        </ng-container>
        <ng-container matColumnDef="marginPercent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Margin %</th>
          <td mat-cell *matCellDef="let element" class='text--sm'>
            {{ element.marginPercent }}%
          </td>
        </ng-container>
        <ng-container matColumnDef="createdAt" style='width: 15%'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Shipment Date</th>
          <td mat-cell *matCellDef="let element">{{ element.createdAt | timeToString : DATE_FORMAT : 'America/Toronto' }}</td>
        </ng-container>
        <ng-container matColumnDef="actions" style='width: 10%'>
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element" class='align-right'>
            <button mat-icon-button color='primary' class='mat-small-icon' (click)='editClaimCostEvent.emit(element)' title='Edit claim amounts'>
              <i class='icon-edit text--base'></i>
            </button>
          </td>
        </ng-container>

        <!-- Adjustment Details -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let shipment" [attr.colspan]="displayedColumns.length">
            <div class="expanded-shipment-detail" [@detailExpand]="shipment === expandedElement ? 'expanded' : 'collapsed'">
              <div class='row mt--md mb--md p--sm'>
                <div class='column--12'>
                  <shipment-details [user]='user' [shipment]='shipment'></shipment-details>
                </div>
              </div>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
        <tr style="height: 0" mat-row *matRowDef="let shipment; columns:['expandedDetail']"></tr>
      </table>
    </div>

    <mat-paginator
      *ngIf='!hideUselessPagination || (hideUselessPagination && totalShipments > itemsPerPage)'
      class='my--sm'
      (page)="pageChanged($event)"
      [length]='totalShipments'
      [pageSize]="itemsPerPage"
      [pageSizeOptions]="itemsPerPageOptions"
      [pageIndex]='currentPage'
      [disabled]='totalShipments <= itemsPerPage'
    >
    </mat-paginator>
  </ng-container>
  <ng-template #noShipments>
    <div class='row justify-content-center mt--lg'>
      <div class='column--12 column-md--4 align-center'>
        <img src='{{ CDN_URL }}/admin/images/empty_animated.svg' />
        <p>No shipments found</p>
      </div>
    </div>
  </ng-template>
</ng-template>
<ng-template #isLoading>
  <div class='align-center mt--lg'>
    <app-loading></app-loading>
  </div>
</ng-template>
