import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoggerService } from '@app/shared/services/logger.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import { HelpersService } from '@app/shared/services/helpers.service';
import { MTX_DRAWER_DATA, MtxDrawerRef } from '@ng-matero/extensions/drawer';
import { ClaimsService } from '@app/shared/services/claims.service';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';

@Component({
  selector: 'app-update-refund-amount',
  templateUrl: './update-refund-amount.component.html',
})
export class UpdateRefundAmountComponent implements OnInit {
  public claim: any;
  public adminUser: AdminUser;
  public form: FormGroup;
  public isLoading: boolean = true;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private logger: LoggerService,
    private bannerNotificationsService: BannerNotificationsService,
    private helpersService: HelpersService,
    public drawerRef: MtxDrawerRef<UpdateRefundAmountComponent>,
    private claimsService: ClaimsService,
    @Inject(MTX_DRAWER_DATA)
    public data: {
      claim: any;
      adminUser: AdminUser;
    }
  ) {}

  ngOnInit() {
    if (
      this.data &&
      this.data.claim &&
      this.data.adminUser &&
      this.userService.hasAuthScope(this.data.adminUser, [AdminScopes.VIEW_CLAIMS])
    ) {
      this.claim = this.data.claim;
      this.adminUser = this.data.adminUser;
      this.createForm();
    } else {
      this.dismissDrawer();
    }
  }

  public submitForm() {
    const formValues = this.form.value;
    const params = {
      claimId: this.claim.id,
      creditAmount: formValues.creditAmount,
      payableAmount: formValues.payableAmount,
      note: formValues.note,
    };

    this.claimsService.updateClaim(this.claim.id, params).subscribe(
      (res) => {
        this.bannerNotificationsService.success('Claim was updated successfully');
        this.logger.log('Update claim success', res);
        this.dismissDrawer(true);
      },
      (err) => {
        this.bannerNotificationsService.error('Updating claim failed');
        this.logger.error('Update claim error', err);
        this.dismissDrawer(false);
      }
    );
  }

  public dismissDrawer(successful: boolean = false): void {
    this.drawerRef.dismiss(successful);
  }

  private createForm() {
    const creditAmount = this.claim.creditAmount;
    const customerAmount = this.claim.customerAmount;
    const machoolAmount = this.claim.machoolAmount;
    const requestedAmount = +this.claim.requestedAmount;
    const payableAmount = +this.claim.payableAmount;

    this.form = this.formBuilder.group({
      requestedAmount: [this.helpersService.convertToCurrency(requestedAmount), Validators.required],
      creditAmount: [this.helpersService.convertToCurrency(creditAmount), Validators.required],
      customerAmount: [this.helpersService.convertToCurrency(customerAmount), Validators.required],
      machoolAmount: [this.helpersService.convertToCurrency(machoolAmount), Validators.required],
      payableAmount: [Math.round(payableAmount * 100) / 100, Validators.required],
      note: [this.claim.note || ''],
    });
    this.form.get('requestedAmount')?.disable();
    this.form.get('creditAmount')?.disable();
    this.form.get('customerAmount')?.disable();
    this.form.get('machoolAmount')?.disable();
    this.isLoading = false;
  }
}
