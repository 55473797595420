import { Component, OnInit } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { ClaimsService } from '@app/shared/services/claims.service';
import { LoggerService } from '@app/shared/services/logger.service';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateFormats, DefaultTimezone, SupportClaimStatus } from '@app/shared/constants';
import { environment } from '../../../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { HelpersService } from '@app/shared/services/helpers.service';
import { PageEvent } from '@angular/material/paginator';
import { SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
})
export class ClaimsComponent implements OnInit {
  public loading: boolean = true;
  public loadingClaims: boolean = true;
  public user: AdminUser;
  public searchForm: FormGroup;
  public statusItems: { id: string; value: string; text: string }[] = [{ id: '', value: '', text: 'All' }];
  public CDN_URL: string = environment.CDN_URL;
  public DATE_FORMAT: string = DateFormats.DATE_SHORT;
  public defaultTimezone = DefaultTimezone;
  public claims = [];
  public sortedData: any = [];
  public currentPage: number = 0;
  public itemsPerPage: number = 10;
  public itemsPerPageOptions: number[] = [10, 25, 50];
  public paginatedClaims: any[] = [];
  public claimsCount: number = 0;
  public displayedColumns: string[] = [
    'companyName',
    'supportTicketId',
    'createdAt',
    'requestedAmount',
    'refundedAmount',
    'status',
  ];
  public isFinanceUser: boolean = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private bannerNotificationsService: BannerNotificationsService,
    private logger: LoggerService,
    private helpersService: HelpersService,
    private sessionStorageService: SessionStorageService,
    private claimsService: ClaimsService
  ) {}

  ngOnInit() {
    this.loading = true;

    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_CLAIMS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.isFinanceUser = this.userService.hasAuthScope(adminUser, [AdminScopes.VIEW_CLAIMS_FINANCE]);
            Object.values(SupportClaimStatus).forEach((status) => {
              this.statusItems.push({ id: status, value: status, text: status });
            });
            this.user = adminUser;
            this.reset();
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  public gotoClaim(claimId): void {
    const searchParams = this.setupSearchQuery();
    this.sessionStorageService.store('claimsSearchParams', searchParams);
    this.router.navigate(['claims', 'ticket', claimId]);
  }

  public sortData(sort: Sort) {
    const data = this.claims.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.sortedData = data.sort((a, b) => this.helpersService.compare(a[sort.active], b[sort.active], isAsc));
    this.pageChanged({ pageSize: this.itemsPerPage, pageIndex: 0, length: this.itemsPerPage });
  }

  public pageChanged(pageEvent: PageEvent): void {
    this.itemsPerPage = pageEvent.pageSize;
    this.currentPage = pageEvent.pageIndex;
    const end = this.itemsPerPage * (this.currentPage + 1);
    const start = end - this.itemsPerPage;
    this.paginatedClaims = this.sortedData.slice(start, end);
  }

  public reset() {
    this.loadingClaims = true;
    this.createForm();
    this.searchClaims();
  }

  public search() {
    this.searchClaims();
  }

  private createForm() {
    this.searchForm = this.formBuilder.group({
      status: [this.isFinanceUser ? SupportClaimStatus.READY_FOR_FINANCE : ''],
      companyName: [],
      ticketId: [],
    });
  }

  private setupSearchQuery(): any {
    const storedSearchParams = this.sessionStorageService.retrieve('claimsSearchParams');
    if (storedSearchParams) {
      this.searchForm.patchValue(storedSearchParams);
      this.sessionStorageService.clear('claimsSearchParams');
    }
    const searchFormVals = this.searchForm.value;
    const searchParams = {
      ticketId: undefined,
      companyName: undefined,
      status: undefined,
    };
    if (searchFormVals.ticketId?.length > 0) {
      searchParams.ticketId =
        typeof searchFormVals.ticketId === 'number' ? parseInt(searchFormVals.ticketId) : searchFormVals.ticketId;
    } else {
      delete searchParams.ticketId;
    }
    if (searchFormVals.companyName?.length > 0) {
      searchParams.companyName = searchFormVals.companyName;
    } else {
      delete searchParams.companyName;
    }
    if (searchFormVals.status?.length > 0) {
      searchParams.status = searchFormVals.status;
    } else {
      delete searchParams.status;
    }
    return searchParams;
  }

  private searchClaims(): void {
    this.currentPage = 0;
    this.claims = [];
    this.paginatedClaims = [];
    this.claimsCount = 0;
    const searchParams = this.setupSearchQuery();

    this.claimsService.getFromAll(searchParams).subscribe(
      (response: any) => {
        const claims = response.claims;
        this.claimsCount = response.totalClaims;

        this.logger.log('Claims GET support claims', response);
        claims.forEach((claim: any) => {
          claim.statusClass = claim.status.toLowerCase().replace(/ /g, '_');
          claim.companyName = claim.company.name;
        });
        this.claims = claims;

        const end = this.itemsPerPage * (this.currentPage + 1);
        const start = end - this.itemsPerPage;
        this.paginatedClaims = this.claims.slice(start, end);

        this.loading = false;
        this.loadingClaims = false;
      },
      (err) => {
        this.bannerNotificationsService.error(err.message, {
          category: 'Claims GET support claims error',
          message: err,
        });
        this.loading = false;
        this.loadingClaims = false;
      }
    );
  }
}
