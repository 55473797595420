import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { AdminScopes, AdminUser, UserService } from '@app/shared/services/user.service';
import { first, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from '@app/shared/services/logger.service';
import { ClaimsService } from '@app/shared/services/claims.service';
import { DateFormats, DefaultTimezone, PanelWidths, RefundMethods, SupportClaimStatus } from '@app/shared/constants';
import { HelpersService } from '@app/shared/services/helpers.service';
import { Sort } from '@angular/material/sort';
import { BannerNotificationsService } from '@app/shared/services/banner-notifications.service';
import * as FileSaver from 'file-saver';
import { MtxDrawer } from '@ng-matero/extensions/drawer';
import { AddCreditNoteComponent } from '@app/pages/authenticated/pages/claims/components/add-credit-note/add-credit-note.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmationComponent } from '@app/shared/components/modal-confirmation/modal-confirmation.component';
import { UpdateRefundAmountComponent } from '@app/pages/authenticated/pages/claims/components/update-refund-amount/update-refund-amount.component';
import { UpdateClaimStatusComponent } from '@app/pages/authenticated/pages/claims/components/update-claim-status/update-claim-status.component';
import { EditShipmentClaimCostComponent } from '@app/pages/authenticated/pages/claims/components/edit-shipment-claim-cost/edit-shipment-claim-cost.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-claim-detail',
  templateUrl: './claim-detail.component.html',
})
export class ClaimDetailComponent implements OnInit, OnDestroy {
  public loading = true;
  public user: AdminUser;
  public claimId: number;
  public claim: any;
  public creditNoteColumns: string[] = ['provider', 'creditNoteName', 'createdAt', 'creditAmount', 'action'];
  public sortedCreditNotes: any = [];
  public canIssueRefund: boolean = false;
  public canCloseClaim: boolean = false;
  public shipmentsWithoutCosts: boolean = false;
  public isFinanceUser: boolean = false;

  protected readonly DATE_FORMAT: string = DateFormats.DATE_TIME_YEAR;
  protected readonly SHORT_DATE_FORMAT: string = DateFormats.DATE_YEAR_SML;
  protected readonly defaultTimezone = DefaultTimezone;
  protected readonly refundMethods = RefundMethods;

  private routeSub: Subscription;
  private gettingCreditNote: boolean = false;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggerService,
    private helpersService: HelpersService,
    private bannerNotificationsService: BannerNotificationsService,
    private claimsService: ClaimsService,
    private dialog: MatDialog,
    private drawer: MtxDrawer
  ) {}

  ngOnInit() {
    this.loading = true;
    this.routeSub = this.route.params.subscribe((params) => {
      if (!this.claimId) {
        this.claimId = params['claimId'];
      }
    });
    this.userService
      .getAuthenticatedUser([AdminScopes.VIEW_CLAIMS])
      .pipe(first())
      .subscribe(
        (adminUser: AdminUser) => {
          if (adminUser) {
            this.user = adminUser;
            this.isFinanceUser = this.userService.hasAuthScope(adminUser, [AdminScopes.VIEW_CLAIMS_FINANCE]);
            this.getClaim();
          } else {
            this.router.navigate(['login']);
          }
        },
        (err) => {
          this.router.navigate(['home']);
        }
      );
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  public copyText(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.style.position = 'fixed';
    textarea.style.top = '0';
    textarea.style.left = '0';
    textarea.style.width = '0';
    textarea.style.height = '0';
    textarea.style.opacity = '0';
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    this.bannerNotificationsService.success('Email copied to clipboard');
  }

  public closeClaim() {
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      width: '600px',
      data: {
        title: 'Confirmation',
        content: `Are you sure you want to close this claim?`,
        confirmBtnLabel: 'Confirm',
      },
    });
    dialogRef.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.claimsService.updateClaim(this.claim.id, SupportClaimStatus.COMPLETED).subscribe(
          (res) => {
            this.bannerNotificationsService.success('Claim was updated successfully');
            this.logger.log('Update claim success', res);
          },
          (err) => {
            this.bannerNotificationsService.error('Updating claim failed');
            this.logger.error('Update claim error', err);
          }
        );
      }
    });
  }

  public updateClaimStatus() {
    const drawerRef = this.drawer.open(UpdateClaimStatusComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { adminUser: this.user, claim: this.claim },
    });
    drawerRef.afterDismissed().subscribe((result) => {
      if (result) {
        this.getClaim();
        this.bannerNotificationsService.success('Claim status has been updated');
      }
    });
  }

  public updateRefundedAmount() {
    const drawerRef = this.drawer.open(UpdateRefundAmountComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { adminUser: this.user, claim: this.claim },
    });
    drawerRef.afterDismissed().subscribe((result) => {
      if (result) {
        this.getClaim();
        this.bannerNotificationsService.success('Refunded amount was saved');
      }
    });
  }

  public editClaimCost(shipment) {
    const drawerRef = this.drawer.open(EditShipmentClaimCostComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { adminUser: this.user, claim: this.claim, shipment },
    });
    drawerRef.afterDismissed().subscribe((result) => {
      if (result) {
        this.getClaim();
        this.bannerNotificationsService.success('Shipment claim amounts have been updated');
      }
    });
  }

  public upsertCreditNote(creditNote?: any) {
    const drawerRef = this.drawer.open(AddCreditNoteComponent, {
      width: PanelWidths.desktopFull,
      disableClose: true,
      closeOnNavigation: false,
      data: { adminUser: this.user, claim: this.claim, creditNote },
    });
    drawerRef.afterDismissed().subscribe((result) => {
      if (result) {
        this.getClaim();
        this.bannerNotificationsService.success('Credit note was saved');
      }
    });
  }

  public downloadNote(creditNote: any) {
    if (!this.gettingCreditNote) {
      this.gettingCreditNote = true;
      this.claimsService.getCreditNote(creditNote.id).subscribe(
        (res: any) => {
          this.logger.log('GET Shipment documents', res);

          let mimeType = res.content;
          let downloadFileName = creditNote.creditNote;

          if (res.data.length > 0) {
            const blob = this.helpersService.b64toBlob(res.data, mimeType);
            FileSaver.saveAs(blob, downloadFileName);
            this.gettingCreditNote = false;
          } else {
            this.bannerNotificationsService.error('Could not get claim note', {
              category: 'GET claim note error',
              message: {
                errors: res,
              },
            });
            this.gettingCreditNote = false;
          }
        },
        (err: any) => {
          this.bannerNotificationsService.error('Could not get claim note', {
            category: 'GET claim note error',
            message: err,
          });
          this.gettingCreditNote = false;
        }
      );
    }
  }

  public removeCreditNote(creditNote: any): void {
    const prettyAmount = this.helpersService.convertToCurrency(creditNote.creditAmount);
    const dialogRef = this.dialog.open(ModalConfirmationComponent, {
      data: {
        title: 'Delete credit note',
        content: `Are you sure you want to delete this credit note from <strong>${creditNote.providerName}</strong> for <strong>${prettyAmount}</strong>?`,
        confirmBtnLabel: 'DELETE',
        btnColor: 'warn',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.claimsService.deleteCreditNote(creditNote.id).subscribe(
          () => {
            this.bannerNotificationsService.success('Credit note successfully deleted');
            this.getClaim();
          },
          (err) => {
            this.bannerNotificationsService.error('Could not delete claim note', {
              category: 'DELETE claim note error',
              message: err,
            });
          }
        );
      }
    });
  }

  public sortData(sort: Sort) {
    const data = this.claim.creditNotes.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedCreditNotes = data;
      return;
    }
    const isAsc = sort.direction === 'asc';
    this.sortedCreditNotes = data.sort((a, b) => this.helpersService.compare(a[sort.active], b[sort.active], isAsc));
  }

  private getClaim() {
    this.loading = true;
    this.claim = undefined;

    this.claimsService.get(this.claimId).subscribe(
      (claim) => {
        this.logger.log('Claim GET support claim', claim);
        claim.companyName = claim.company?.name;
        claim.statusClass = claim.status.toLowerCase().replace(/ /g, '_');
        claim.department = claim.supportTicket?.custom_fields?.cf_department;
        claim.claimCategory = claim.supportTicket?.custom_fields?.cf_category;
        claim.situation = claim.supportTicket?.custom_fields?.cf_situation;
        claim.caseAgent = claim.supportContact?.contact?.name || 'N/A';
        claim.creditAmount = 0;
        claim.customerAmount = 0;
        claim.machoolAmount = 0;
        let claimPayable = 0;

        let totalShipmentsWithCostsSet = 0;
        claim.shipments.forEach((shipment) => {
          claim.customerAmount += shipment.claimCustomerAmount;
          claim.machoolAmount += shipment.claimMachoolAmount;
          claim.creditAmount += shipment.claimCreditAmount;
          claimPayable += shipment.claimPaymentAmount;

          if (shipment.claimCustomerAmount > 0 && shipment.claimMachoolAmount > 0) {
            totalShipmentsWithCostsSet += 1;
          }
        });

        claim.payableAmount = _.isNil(claim.payableAmount) ? claimPayable || 0 : claim.payableAmount;
        claim.creditNotes.forEach((creditNote) => {
          creditNote.providerName = this.claimsService.convertCreditNoteProvider(creditNote.provider);
          const creditNoteArray = creditNote.creditNote.split('/');
          creditNote.creditNoteName = creditNoteArray[creditNoteArray.length - 1].split('?')[0];
        });
        this.sortedCreditNotes = claim.creditNotes;

        this.shipmentsWithoutCosts = totalShipmentsWithCostsSet !== claim.shipments.length;
        this.canCloseClaim = claim.status === SupportClaimStatus.READY_FOR_FINANCE;
        this.canIssueRefund =
          this.sortedCreditNotes.length > 0 && totalShipmentsWithCostsSet === claim.shipments.length;

        this.claim = claim;
        this.loading = false;
      },
      (err) => {
        this.logger.error('Claim GET support claim error', err);
        this.router.navigate(['claims']);
      }
    );
  }
}
