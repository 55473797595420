<div class='row row--valign-center'>
  <div class='column--8'>
    <h3>Add / edit refunded amount</h3>
  </div>
  <div class='column--4 align-right'>
    <button mat-icon-button color='primary' (click)='dismissDrawer()'><mat-icon>close</mat-icon></button>
  </div>
</div>
<form [formGroup]="form">
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Requested Amount</mat-label>
        <input
          matInput
          formControlName='requestedAmount'
          name='requestedAmount'
          [matTooltip]="'Amount claimed by client / Item(s) value'"
          matTooltipClass='nowrap-tooltip'
          matTooltipPosition="above"
        >
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Customer Cost Amount</mat-label>
        <input
          matInput
          formControlName='customerAmount'
          name='customerAmount'
          [matTooltip]="'Amount charged to customer by Machool'"
          matTooltipClass='nowrap-tooltip'
          matTooltipPosition="above"
        >
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Machool Billed Amount</mat-label>
        <input
          matInput
          formControlName='machoolAmount'
          name='machoolAmount'
          [matTooltip]="'Amount billed to Machool by courier'"
          matTooltipClass='nowrap-tooltip'
          matTooltipPosition="above"
        >
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Credit Amount</mat-label>
        <input
          matInput
          formControlName='creditAmount'
          name='creditAmount'
          [matTooltip]="'Amount credited to Machool by courier/insurance'"
          matTooltipClass='nowrap-tooltip'
          matTooltipPosition="above"
        >
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <mat-form-field class='full-width'>
        <mat-label>Payable Amount</mat-label>
        <mat-icon matIconPrefix>attach_money</mat-icon>
        <input
          matInput
          formControlName='payableAmount'
          name='payableAmount'
          [matTooltip]="'Credit applied to customer by Machool'"
          matTooltipClass='nowrap-tooltip'
          matTooltipPosition="above"
          value=""
        >
      </mat-form-field>
    </div>
  </div>
  <div class='row'>
    <div class='column--12'>
      <div class='full-width'>
        <label class='mb--sm'>Additional note</label>
        <quill-editor class="w-100" name="note" formControlName='note'></quill-editor>
      </div>
    </div>
  </div>
</form>

<div class='row mt--md'>
  <div class='column--6'>
    <button mat-flat-button color='accent' (click)='dismissDrawer()'>Cancel</button>
  </div>
  <div class='column--6 align-right'>
    <button mat-flat-button color='primary' (click)='submitForm()' [disabled]='form.invalid'>Save</button>
  </div>
</div>
